import styled from 'styled-components'
import { useCallback, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Button, useModal } from '@asksifu/uikit'
import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice'
import BigNumber from 'bignumber.js'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { useCurrency } from '../../hooks/Tokens'
import { ToastDescriptionWithTx } from '../../components/Toast'
import useToast from '../../hooks/useToast'
import { useVesting2Contract } from '../../hooks/useContract'
import { useTranslation } from '../../contexts/Localization'
import useActiveWeb3React from '../../hooks/useActiveWeb3React'

const Select = styled.select`
  padding: 8px;
  border-radius: 15px;
  @media (max-width: 900px) {
    padding: 5px;
  }
`

const Div = styled.div`
  padding: 8px;
  font-family: Helvetica;
  letter-spacing: 2px;
  line-height: 2;
  @media (max-width: 900px) {
    padding: 5px;
    letter-spacing: 1px;
    line-height: 1.5;
  }
`

const Span = styled.span`
  font-size: 12px;
  @media (max-width: 900px) {
    display: flex;
  }
`

const DivFlex = styled.div`
  padding: 8px;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 900px) {
    padding: 5px;
  }
`

const DivNoWrapFlex = styled.div`
  padding: 8px;
  flex-wrap: nowrap;
  align-items: center;
  display: flex;

  @media (max-width: 900px) {
    padding: 5px;
  }
`

const DivFlexNoWrapLaptopWrapMobile = styled.div`
  padding: 8px;
  display: flex;
  justify-content: left;
  align-items: flex-start;
  flex-wrap: wrap;

  @media (max-width: 900px) {
    flex-direction: column;
    padding: 5px;
  }
`

function Home() {
  const { t } = useTranslation()

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [currencyValue, setCurrencyValue] = useState(null)
  const [currencyObj] = [useCurrency(currencyValue) ?? undefined]

  const [currencyInput, setCurrencyInput] = useState('0');

  const [currencyAddress, setCurrencyAddress] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState(null);
  const [currencyDecimals, setCurrencyDecimals] = useState(0);

  const { toastSuccess, toastError } = useToast()
  const { callWithGasPrice } = useCallWithGasPrice()
  const vesting2Contract = useVesting2Contract()

  const { account } = useActiveWeb3React()

  const changeNetwork = async (chainId: string) => {
    if (window.ethereum) {
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: `0x${chainId}`  }],
          });
      } catch (error) {
        console.error(error);
      }
    }
  }

  const handleTypeInput = (x) => {
    setCurrencyInput(x)
  }

  const handleSelectCurrency = useCallback(
    (currency: any) => {
     setCurrencyAddress(currency.address)
     setCurrencyDecimals(currency.decimals)
     setCurrencySymbol(currency.symbol)
    },
    [],
  )

  const create = async () => {
    if(!account) {
      toastError(t('Error'), t('Please connect wallet!'))
      return
    }

    if(!currencyAddress || !currencyDecimals) {
      toastError(t('Error'), t('Please key in currency address!'))
      return
    }

    if(currencyInput === '0' ) {
      toastError(t('Error'), t('Please key in currency amount!'))
      return
    }

    const startDateEpoch = Number(startDate)
    const endDateEpoch = Number(endDate)
    const currencyInputInBase =
      new BigNumber(currencyInput).times(currencyDecimals).toNumber()

    try {
      const tx = await callWithGasPrice(vesting2Contract, 'create2', [
        startDateEpoch,
        endDateEpoch,
        currencyAddress,
        currencyInputInBase,
      ])

      toastSuccess(`${t('Transaction Submitted')}!`, <ToastDescriptionWithTx txHash={tx.hash} />)
      const receipt = await tx.wait()
      if (receipt.status) {
        toastSuccess(t('Created'), t('.'))
      } else {
        toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
      }
    } catch (err: any) {
      const errMessage = err?.data?.message
      if (errMessage) {
        toastError(t('Error'), err?.data?.message)
      }
    } finally {
      // console.log(1)
    }
  }

  // if (!account) {
  //   return (
  //     <>
  //       <Div>
  //         <Div>
  //           AskSifu is a decentralized daily vesting cryptocurrency system powered by EVM smart contract technology
  //           that enables users to save their crypto and receive an equal amount of crypto back from their crypto savings every day.
  //         </Div>
  //         <Div>
  //           Remember to connect wallet to proceed.
  //         </Div>
  //       </Div>
  //     </>
  //   )
  // }

  // eslint-disable-next-line consistent-return
  return (
    <Div>
      <Div>
        AskSifu is a decentralized daily vesting cryptocurrency system powered by EVM smart contract technology
        that enables users to save their crypto and receive an equal amount of crypto back from their crypto savings every day.
      </Div>
      <DivFlex>
        <Div>
          Choose your network.
        </Div>
        <Div>
          <Select onChange={e => changeNetwork(e.target.value)}>
            <option value="38">Binance Smart Chain</option>
            <option value="61">Binance Smart Chain Testnet</option>
          </Select>
        </Div>
      </DivFlex>
      <DivFlexNoWrapLaptopWrapMobile>
        <DivNoWrapFlex>
          Choose your distribution period.
        </DivNoWrapFlex>
        <DivNoWrapFlex>
          <Div>
            Start Date:
          </Div>
          <Div>
            <DatePicker minDate={new Date()} selected={startDate} onChange={(date: any) => setStartDate(date)}/>
          </Div>
        </DivNoWrapFlex>
        <DivNoWrapFlex>
          <Div>
            End Date:
          </Div>
          <Div>
            <DatePicker minDate={new Date()} selected={endDate} onChange={(date: any) => setEndDate(date)}/>
          </Div>
        </DivNoWrapFlex>
      </DivFlexNoWrapLaptopWrapMobile>
      <DivFlexNoWrapLaptopWrapMobile>
        <Div>
          <Span>
            {currencyAddress? `Address: ${currencyAddress}`: null}
          </Span>
          &nbsp;&nbsp;&nbsp;
          <Span>
            {currencySymbol? `Symbol: ${currencySymbol}`: null}
            &nbsp;&nbsp;&nbsp;
            {currencyDecimals? `Decimals: ${currencyDecimals}`: null}
          </Span>
          <CurrencyInputPanel
            label='From'
            value={currencyInput}
            showMaxButton={false}
            currency={currencyObj}
            onUserInput={handleTypeInput}
            onMax={undefined}
            onCurrencySelect={handleSelectCurrency}
            otherCurrency={undefined}
            id="currency-input"
          />
        </Div>
      </DivFlexNoWrapLaptopWrapMobile>
      <DivFlex>
        <Button type="button" onClick={create}>
          Create the contract
        </Button>
      </DivFlex>
    </Div>
  )
}

export default Home
